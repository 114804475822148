import React from 'react';
import { PortfolioPage } from '../../modules/PortfolioPage';
import { listItems } from '../../utils/data/projectList';
import { VCSectionBlock } from '../../globalComponents/VCSectionBlock';
import challengeImage from '../../images/projects/Flare/challenge.jpg';
import challengeImagew from '../../images/projects/Flare/challenge.webp';
import inspector from '../../images/projects/Flare/Flare.mp4';
import inspectorw from '../../images/projects/Flare/Flare.webm';
import galileo from '../../images/projects/Flare/Galileo.mp4';
import galileow from '../../images/projects/Flare/Galileo.webm';

// markup
const ProjectPage = () => {
  const projectData = listItems[0];
  const totalBlocks = 6;

  return (
    <main>
      <PortfolioPage metaData={projectData}>
        <VCSectionBlock
          title="The challenge"
          sectionNumber={1}
          sectionTotal={totalBlocks}
          image={{
            jpeg: challengeImage,
            webp: challengeImagew,
            alt: `Bitso's widly different UI for the same feature.`,
          }}
        >
          <p>
            <a href="https://www.bitso.com/" target="_blank">
              Bitso{' '}
            </a>
            been an international brand, there was a need Improve the visual,
            content, brand, and functional consistency across products and
            platforms.
          </p>
        </VCSectionBlock>
        <VCSectionBlock
          title="The basics"
          sectionNumber={2}
          sectionTotal={totalBlocks}
          iFrame={{
            src: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FtME6tH8PqFoRQZe1gd16hG%2FFlare-Public%3Fnode-id%3D1%253A22%26t%3D5hRL9u4WUdJzg9ms-1',
            alt: `THE FIGMA FILE CONTAINING THE BASIC ELEMENTS OF THE DESIGN SYSTEM.`,
          }}
        >
          <p>
            The first thing we needed to do was stablish our foundations. <br />
            These foundations represent Bitso's Design Language through basic
            guidelines for designers and developers to make informed decisions
            about style. One highlight from this is that, for colors, we
            organized them into theme-like structure so you could change between
            them really easily in every platform.
          </p>
        </VCSectionBlock>
        <VCSectionBlock
          title="The components"
          sectionNumber={3}
          sectionTotal={totalBlocks}
          iFrame={{
            src: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FtME6tH8PqFoRQZe1gd16hG%2FFlare-Public%3Fnode-id%3D1%253A33312%26t%3D5hRL9u4WUdJzg9ms-1',
            alt: `THE FIGMA FILE CONTAINING FLARE'S COMPONENTS.`,
          }}
        >
          <p>
            Once the foundations were stablished, we started creating components
            for the mobile app and for the website. Each one following the best
            practices and our component guidelines.
          </p>
        </VCSectionBlock>
        <VCSectionBlock
          title="Flare inspector"
          sectionNumber={4}
          sectionTotal={totalBlocks}
          video={{
            mp4: inspector,
            webm: inspectorw,
            alt: `Showcasing the Flare inspector plugin to the team.`,
          }}
        >
          <p>
            The Flare inspector was a Figma plugin I created to increase the
            adoption of the design systems on other squads, it can tell you what
            are the things that does not align with the design system guidelines
            and how to correct them.
          </p>
        </VCSectionBlock>
        <VCSectionBlock
          title="Galileo"
          sectionNumber={5}
          sectionTotal={totalBlocks}
          video={{
            mp4: galileo,
            webm: galileow,
            alt: `A showcase of Galileo.`,
          }}
        >
          <p>
            Mantaining a Design system is not easy, so I also created a service
            that uses data in a spreadsheet to automatically update our code
            base, our documentation and it also conects to a Figma plugin so you
            can get updates libraries really easy.
          </p>
        </VCSectionBlock>
        <VCSectionBlock
          title="Final thougths"
          sectionNumber={6}
          sectionTotal={totalBlocks}
          iFrame={{
            src: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FtME6tH8PqFoRQZe1gd16hG%2FFlare-Public%3Fnode-id%3D1%253A56497%26scaling%3Dmin-zoom%26page-id%3D1%253A53382%26starting-point-node-id%3D1%253A56375',
            alt: `An example of screens with Flare.`,
          }}
        >
          <p>
            Flare was an incredible challenge about scaling a Design system to
            serve different teams with on different platforms, each one with
            it's own little identity that branches from the main brand.
          </p>
        </VCSectionBlock>
      </PortfolioPage>
    </main>
  );
};

export default ProjectPage;
